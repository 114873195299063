import React, { Component } from "react";
import { Box } from "@mui/material";

export class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Box className="notification-container">Notification</Box>;
  }
}
export default Notification;
