import { Component } from "react";
import { Box } from "@mui/material";

class ConfigureTopology extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Box>
        <h4>Configure Topology Page</h4>
      </Box>
    );
  }
}

export default ConfigureTopology;
