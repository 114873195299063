import React, { Component } from "react";
import { Box } from "@mui/material";

class Database extends Component {
  render() {
    return <Box>Database</Box>;
  }
}

export default Database;
