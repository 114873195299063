import Box from "@mui/material/Box";
import { Component } from "react";

class ReviewConfigureCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Box>
        <h4>Review & Configure Creation Page</h4>
      </Box>
    );
  }
}

export default ReviewConfigureCreation;
