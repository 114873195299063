import Box from "@mui/material/Box";
import { Component } from "react";

class SelectRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Box>
        <h4>Select Region page</h4>
      </Box>
    );
  }
}

export default SelectRegion;
