import React, { Component } from 'react'

class GcpComponent extends Component {
  render() {
    return (
      <div>GcpComponent</div>
    )
  }
}

export default GcpComponent;