import React, { Component } from 'react'

class AzureComponent extends Component {
  render() {
    return (
      <div>AzureComponent</div>
    )
  }
}

export default AzureComponent;