import React, { Component } from "react";
import { Box } from "@mui/material";

class AccountPricing extends Component {
  render() {
    return <Box>AccountPricing</Box>;
  }
}

export default AccountPricing;
