import React, { Component } from "react";
import { Box } from "@mui/material";

export class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Box className="billing-container">Billing</Box>;
  }
}
export default Billing;
