import React, { Component } from "react";
import { Box } from "@mui/material";

class Container extends Component {
  render() {
    return <Box>Container</Box>;
  }
}

export default Container;
